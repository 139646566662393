const regex = /([^&=?]*)=([^&=?]*)/g;

export const getAnchorParams = (anchor: string): null | { [key: string]: string } => {
  if (anchor.charAt(0) !== "#") {
    return null;
  }
  anchor = anchor.substring(1);
  const params: { [key: string]: string } = {};
  let match;
  while ((match = regex.exec(anchor))) {
    const paramName = match[1];
    const paramValue = match[2];
    params[paramName] = paramValue;
  }

  return params;
};
