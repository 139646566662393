import React, { useEffect, type FunctionComponent, useMemo } from "react";
import { getAnchorParams } from "../util";
import { Helmet } from "react-helmet";
import { AppLogo, DownloadApp } from "../components";
import { Box, Button, Container, Divider, Hidden, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProps {}

export const AddPlaylist: FunctionComponent<IProps> = () => {
  const { t } = useTranslation();

  const deepLink = `seeclap://add-playlist${window.location.hash || ""}`;

  console.log("deepLink:", deepLink);

  const pressHandler = async () => {
    const result = await openAppURL(deepLink);
    // console.log("result:", result);
    // console.log("result:", result);
    // console.log("result:", result);
    // console.log("result:", result);
  };

  return (
    <Container maxWidth="md">
      <Box height="100vh" display="flex" flexDirection="column" justifyContent={"center"}>
        <Helmet>
          <meta name="apple-itunes-app" content={`app-id=${process.env.REACT_APP_APPLE_ID}, app-argument=${deepLink}`} />
          <title>{t("page_add_playlist.title")}</title>
          <meta name="description" content={t("page_add_playlist.description")} />
        </Helmet>

        <Grid container spacing={2} justifyContent="space-around" alignItems="center">
          <Grid xs={12} md={5}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
              <AppLogo />

              <Typography align="center" variant="subtitle2" component="h1" marginBottom={3}>
                {t("app_redirection_error")}
              </Typography>

              <Button variant="contained" onClick={pressHandler}>
                {t("button.open_app")}
              </Button>
            </Stack>
          </Grid>
          <Hidden mdDown>
            <Divider orientation="vertical" flexItem />
          </Hidden>
          <Hidden smUp>
            <Divider flexItem variant="middle" sx={{ flex: 1, marginY: 3 }} />
          </Hidden>
          <Grid xs={12} md={5} justifyContent="center" alignItems="center">
            <DownloadApp deepLink={deepLink} />
            {/* <Typography align="center" variant="h4" component="h3" marginBottom={3}>
              Scan to download <br />
              the app
            </Typography>

            <Stack marginY={3} alignItems="center">
              <QRCode value={deepLink} size={120} />
            </Stack>

            <Stack marginY={3}>
              <Divider variant="middle">OR</Divider>
            </Stack>

            <Stack marginY={3} justifyContent="center" alignItems="center" sx={{ flexDirection: { md: "row" } }}>
              <Stack marginX={2}>
                <ButtonAppStoreDownload onClick={pressDowHandler} />
              </Stack>
              <Stack marginX={2} style={{opacity:0.1}}>
                <ButtonPlayStoreDownload />
              </Stack>
            </Stack> */}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

function getCurrentUrlWithoutHash() {
  // Récupérer l'URL complète avec le fragment
  const fullUrl = window.location.href;

  // Supprimer le fragment en divisant l'URL par le symbole #
  const urlParts = fullUrl.split("#");
  const urlWithoutHash = urlParts[0];

  return urlWithoutHash;
}

function op__enAppURL(appURL: string): Promise<boolean> {
  return new Promise((resolve) => {
    const link = document.createElement("a");
    link.href = appURL;
    link.onload = () => resolve(true);
    link.onerror = () => resolve(false);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
}
function openAppURL(appURL: string): Promise<boolean> {
  return new Promise((resolve) => {
    const link = document.createElement("a");
    link.href = appURL;
    //link.target = '_blank'
    link.oncancel = () => {
      console.log("oncancel:");
    };
    link.onclose = () => {
      console.log("onclose:");
    };
    void document.body.appendChild(link);

    //   window.addEventListener("pagehide", function() {
    //     console.log('pagehide:')

    // });

    const handleVisibilityChange = () => {
      console.log("document.visibilityState:", document.visibilityState);

      if (document.visibilityState === "visible") {
        void resolve(true); // L'utilisateur est toujours sur la page
      } else {
        void resolve(false); // L'utilisateur a quitté la page (refus de redirection)
      }
      void document.removeEventListener("visibilitychange", handleVisibilityChange);
    };

    void document.addEventListener("visibilitychange", handleVisibilityChange);

    // Déclenche le clic après un court délai pour laisser le temps de gérer l'événement visibilitychange
    void setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 100);
  });
}
