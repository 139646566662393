import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enLocales from "./en";
// import frLocales from './fr';
// import vnLocales from './vn';
// import cnLocales from './cn';
// import arLocales from './ar';

// ----------------------------------------------------------------------

const defaultLang = "en";
let lng = defaultLang;

if (typeof window !== "undefined") {
  lng = localStorage.getItem("i18nextLng") || defaultLang;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
    },
    lng: lng,
    fallbackLng: lng,
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
