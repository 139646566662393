import { Divider, Stack, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { ButtonAppStoreDownload } from "./ButtonAppStoreDownload";
import { ButtonPlayStoreDownload } from "./ButtonPlayStoreDownload";
import React, { useCallback, type FunctionComponent } from "react";
import { useDevice } from "../hooks";
import { DEVICE_OS } from "../contexts";

interface IProps {
  deepLink: string;
}
export const DownloadApp: FunctionComponent<IProps> = ({ deepLink }) => {
  const { os } = useDevice();
  console.log("os:", os);

  const pressDowHandler = () => {
    const externalURL = "https://apps.apple.com/app/seeclap/id1624494373";
    window.location.href = externalURL;
  };

  const qrcode = useCallback(() => {
    if (os === DEVICE_OS.IOS || os === DEVICE_OS.ANDROID) {
      return;
    }
    return (
      <React.Fragment>
        <Typography align="center" variant="h5" component="h2" marginBottom={3}>
          Scan to download <br />
          the app
        </Typography>
        <Stack marginY={3} alignItems="center">
          <QRCode value={deepLink} size={120} />
        </Stack>
        <Stack marginY={3}>
          <Divider variant="middle">OR</Divider>
        </Stack>
      </React.Fragment>
    );
  }, []);

  const aa = useCallback(() => {
    if (!os || os === DEVICE_OS.MACOS) {
      return;
    }

    return (
      <React.Fragment>
        <Typography align="center" variant="h5" component="h2" marginBottom={3}>
          Télécharger l'application
        </Typography>
        <Stack marginY={3} alignItems="center">
          <Typography align="center" variant="caption" component="span" marginBottom={3}>
            Télécharger l'application
          </Typography>
        </Stack>
      </React.Fragment>
    );
  }, []);
  return (
    <React.Fragment>
      {qrcode()}
      {aa()}

      <Stack marginY={3} justifyContent="center" alignItems="center" sx={{ flexDirection: { md: "row" } }}>
        {os !== DEVICE_OS.ANDROID && (
          <Stack marginX={2}>
            <ButtonAppStoreDownload onClick={pressDowHandler} />
          </Stack>
        )}
        {os !== DEVICE_OS.MACOS && os !== DEVICE_OS.IOS && (
          <Stack marginX={2} style={{ opacity: 0.1 }}>
            <ButtonPlayStoreDownload />
          </Stack>
        )}
      </Stack>
    </React.Fragment>
  );
};
