import React, { type FunctionComponent, type PropsWithChildren, createContext, useEffect, useState } from "react";
import copy from "copy-to-clipboard";

const KEY_STORAGE = "deep_link";
interface IDeepLinkContext {
  setDeepLink: (deepLink: string) => void;
}

const DeepLinkContext = createContext<IDeepLinkContext>({
  setDeepLink: (_deepLink: string) => {},
});

const DeepLinkProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  // const [currentDeepLink, setCurrentDeepLink] = useState();
  void useEffect(() => {
    const deepLinkString = localStorage.getItem(KEY_STORAGE);
    let deepLink: null | string = null;

    try {
      if (!deepLinkString) {
        return;
      }
      const { url, expiredAt } = DeepLink.parse(deepLinkString);
      if (Date.now() > expiredAt) {
        throw new Error("Deep link has expired.");
      }
      deepLink = url;
    } catch (error) {
      console.error("Error handling deep link:", error);
      void localStorage.clear();
    } finally {
      if (!("ReactNativeWebView" in window)) {
        return;
      }
      void (window.ReactNativeWebView as any).postMessage(deepLink);
      void localStorage.clear();
    }
  }, []);

  // useEffect(() => {
  //   const { pathname, hash } = window.location;
  //   console.log('pathname:', pathname)
  //   setCurrentDeepLink({ pathname });
  // }, []);

  const setDeepLink = (deepLink: string, encrypt?: boolean) => {
    void localStorage.setItem(KEY_STORAGE, new DeepLink(deepLink).toString());
    void copy(deepLink);
  };
  return <DeepLinkContext.Provider value={{ setDeepLink }}>{children}</DeepLinkContext.Provider>;
};

export { DeepLinkContext, DeepLinkProvider };

class DeepLink {
  public readonly url: string;
  public readonly expiredAt: number;

  constructor(url: string, expiredAt?: number) {
    this.url = url;
    this.expiredAt = expiredAt || Date.now() + 60 * 20 * 1000;
  }

  public static parse = (dataString: string) => {
    const data = JSON.parse(dataString);
    if (!("url" in data) || typeof data.url !== "string" || !("expiredAt" in data) || typeof data.expiredAt !== "number") {
      throw new Error("");
    }

    return new DeepLink(data.url, data.expiredAt);
  };

  public toString = () => {
    return JSON.stringify(this);
  };
}
