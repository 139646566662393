import { useTranslation } from "react-i18next";

export const ButtonPlayStoreDownload = () => {
  const { t, i18n } = useTranslation();

  return (
    <a href={`https://play.google.com/store/apps/details?id=com.codingbyjerez.seeclap`} target="_blank">
      <img src={`/assets/play-store-download-button/${i18n.language}/black.png`} alt={t("BUTTON.DOWNLOAD_IN_APP_STORE")} style={{ height: "50px" }} />
    </a>
  );
};
