// import { APP_APPLE_URL } from "src/config";

import React, { type FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  onClick?: VoidFunction;
}
export const ButtonAppStoreDownload: FunctionComponent<IProps> = ({ onClick }) => {
  const { t, i18n } = useTranslation();

  if (onClick) {
    return (
      <a onClick={onClick}>
        <img src={`/assets/app-store-download-button/${i18n.language}/black.svg`} alt={t("BUTTON.DOWNLOAD_IN_APP_STORE")} style={{ height: "50px" }} />
      </a>
    );
  }
  return (
    <a href={process.env.REACT_APP_APPLE_URL} target="_blank">
      <img src={`/assets/app-store-download-button/${i18n.language}/black.svg`} alt={t("BUTTON.DOWNLOAD_IN_APP_STORE")} style={{ height: "50px" }} />
    </a>
  );
};
