import React, { type FunctionComponent, createContext, type PropsWithChildren, useState } from "react";

interface IHeadContext {}

const HeadContext = createContext<IHeadContext>({});

const HeadProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [data, setData] = useState<any>({});

  const { title, url, image, description } = data;

  return (
    <HeadContext.Provider value={{}}>
      {/* <head>
        <meta property="og:title" content={title} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content={image} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <title>{`${title} | SeeClap`}</title>
        <meta name="apple-itunes-app" content={`app-id=${process.env.APP_APPLE_ID}`} />
        <meta name="description" content={description} />
      </head>
      <body> */}
        
        {children}
        {/* </body> */}
    </HeadContext.Provider>
  );
};

export { HeadContext, HeadProvider };
