import React from "react";
import ReactDOM from "react-dom/client";
//import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { DeepLinkProvider, DeviceProvider, HeadProvider, LocalProvider } from "./contexts";
import "./locales/i18n";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Home, Share, AddPlaylist, Connection } from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/share",
    element: <Share />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/add-playlist",
    element: <AddPlaylist />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/connection",
    element: <Connection />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <DeviceProvider>
      <DeepLinkProvider>
        <LocalProvider>
          <HeadProvider>
            <RouterProvider router={router} />
          </HeadProvider>
        </LocalProvider>
      </DeepLinkProvider>
    </DeviceProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
