const en = {
 page_share:{
    title:"Share link - SeeClap",
    description:"Media sharing link - SeeClap"
 },
 page_add_playlist:{
    title:"Add Playlist - SeeClap",
    description:"Auto Add Playlist - SeeClap"
 },
 button:{
    open_app:"Open app",
 },

 app_redirection_error: "We were unable to redirect you to our application. It is not installed on your device or Your browser has blocked the redirection."
};

export default en;
