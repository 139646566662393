import DeviceDetector from "device-detector-js";
import React, { type FunctionComponent, type PropsWithChildren, createContext, useMemo } from "react";

interface IDeviceContext {
  os: null | DEVICE_OS;
  type: null | DEVICE_TYPE;
}

const DeviceContext = createContext<IDeviceContext>({
  os: null,
  type: null,
});

const DeviceProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const device = useMemo(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;

    const result = deviceDetector.parse(userAgent);

    console.log("result.os?.name:", result.os?.name);
    console.log("result:", result);

    return {
      os: getOsDevice(result.os?.name),
      type: getTypeDevice(result.device?.type),
    };
  }, []);

  return <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>;
};

export { DeviceContext, DeviceProvider };

export enum DEVICE_OS {
  MACOS = 1,
  IOS = 2,
  ANDROID = 3,
}

export enum DEVICE_TYPE {
  MOBILE = 1,
  TABLET = 2,
  DESKTOP = 3,
}

const getOsDevice = (name?: string): DEVICE_OS | null => {
  switch (name) {
    case "Mac":
      return DEVICE_OS.MACOS;
    case "iOS":
      return DEVICE_OS.IOS;
    case "Android":
      return DEVICE_OS.ANDROID;
    default:
      return null;
  }
};

const getTypeDevice = (name?: string): DEVICE_TYPE | null => {
  switch (name) {
    case "mobile":
      return DEVICE_TYPE.MOBILE;
    case "tablet":
      return DEVICE_TYPE.TABLET;
    case "desktop":
      return DEVICE_TYPE.DESKTOP;
    default:
      return null;
  }
};
