import React, { type FunctionComponent, createContext, type PropsWithChildren, useState } from "react";

interface ILocalContext {}

const LocalContext = createContext<ILocalContext>({});

const LocalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState<string>("en");

  return (
    <LocalContext.Provider value={{ locale }}>
      <html lang={locale}>{children}</html>
    </LocalContext.Provider>
  );
};

export { LocalContext, LocalProvider };
