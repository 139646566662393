
import React, { useEffect, type FunctionComponent } from "react";
import { getAnchorParams } from "../util";
import { Helmet } from "react-helmet";
import { AppLogo, DownloadApp } from "../components";
import { Box, Button, Container, Divider, Hidden, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useTranslation } from "react-i18next";

interface IProps {}

export const Connection: FunctionComponent<IProps> = () => {
  const { t } = useTranslation();
  const deepLink = `seeclap://share${window.location.hash}`;

  useEffect(() => {
    // Récupérer l'ancre de l'URL complète
    let params = getAnchorParams(window.location.hash);
    if (!params || "type" in params || !["movie", "tv"].includes(params.type) || "id" in params) {
      return;
    }

    // const type = params.type;
    const id = parseInt(params.type, 16);

    if (!id) {
      return;
    }
    localStorage.setItem("deep_link", deepLink);
    console.log("Ancres:", params);
  }, []);

  const pressHandler = async () => {
    const result = await openAppURL(deepLink);
    // console.log("result:", result);
    // console.log("result:", result);
    // console.log("result:", result);
    // console.log("result:", result);
  };

  // const pressDowHandler = () => {
  //   localStorage.setItem("deep_link", deepLink);
  //   copy(deepLink);
  //   const externalURL = "https://apps.apple.com/app/seeclap/id1624494373";
  //   window.location.href = externalURL;
  // };

  return (
    <Container maxWidth="md">
      <Box height="100vh" display="flex" flexDirection="column" justifyContent={"center"}>
        <Helmet>
          <meta name="apple-itunes-app" content={`app-id=${process.env.REACT_APP_APPLE_ID}, app-argument=${deepLink}`} />
          <title>{t("page_share.title")}</title>
          <meta name="description" content={t("page_share.description")} />
        </Helmet>

        <Grid container spacing={2} justifyContent="space-around" alignItems="center">
          <Grid xs={12} md={5}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
              <AppLogo />

              <Typography align="center" variant="subtitle2" component="h1" marginBottom={3}>
                {t("app_redirection_error")}
              </Typography>

              <Button variant="contained" onClick={pressHandler}>
                {t("button.open_app")}
              </Button>
            </Stack>
          </Grid>
          <Hidden mdDown>
            <Divider orientation="vertical" flexItem />
          </Hidden>
          <Hidden smUp>
            <Divider flexItem variant="middle" sx={{ flex: 1, marginY: 3 }} />
          </Hidden>
          <Grid xs={12} md={5} justifyContent="center" alignItems="center">
            <DownloadApp deepLink={deepLink} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

function op__enAppURL(appURL: string): Promise<boolean> {
  return new Promise((resolve) => {
    const link = document.createElement("a");
    link.href = appURL;
    link.onload = () => resolve(true);
    link.onerror = () => resolve(false);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
}
function openAppURL(appURL: string): Promise<boolean> {
  return new Promise((resolve) => {
    const link = document.createElement("a");
    link.href = appURL;
    //link.target = '_blank'
    link.oncancel = () => {
      console.log("oncancel:");
    };
    link.onclose = () => {
      console.log("onclose:");
    };
    void document.body.appendChild(link);

    //   window.addEventListener("pagehide", function() {
    //     console.log('pagehide:')

    // });

    const handleVisibilityChange = () => {
      console.log("document.visibilityState:", document.visibilityState);

      if (document.visibilityState === "visible") {
        void resolve(true); // L'utilisateur est toujours sur la page
      } else {
        void resolve(false); // L'utilisateur a quitté la page (refus de redirection)
      }
      void document.removeEventListener("visibilitychange", handleVisibilityChange);
    };

    void document.addEventListener("visibilitychange", handleVisibilityChange);

    // Déclenche le clic après un court délai pour laisser le temps de gérer l'événement visibilitychange
    void setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 100);
  });
}
